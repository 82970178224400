import React, { Suspense, useEffect } from 'react'
import { HashRouter, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { CSpinner, useColorModes } from '@coreui/react-pro'
import './scss/style.scss'
import store from './store'
import FormLoading from './old_components/UI/CSprinner/FormLoading'
// import Colors from './views/theme/colors/Colors'

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages Not rly used
const Login = React.lazy(() => import('./Modules/Auth/Login'))
const Register = React.lazy(() => import('./Modules/Auth/Register'))
const Page404 = React.lazy(() => import('./Modules/ErrorPages/Page404'))
const Page500 = React.lazy(() => import('./Modules/ErrorPages/Page500'))

// Email App
// const EmailApp = React.lazy(() => import('./views/apps/email/EmailApp'))

const App = () => {
  const dispatch = useDispatch()
  const { isColorModeSet, setColorMode } = useColorModes(
    'coreui-pro-react-admin-template-theme-light',
  )
  const storedTheme = useSelector((state) => state.theme)
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.href.split('?')[1])
    const theme = urlParams.get('theme') && urlParams.get('theme').match(/^[A-Za-z0-9\s]+/)[0]
    if (theme) {
      // setColorMode(theme)
      setColor(theme)
    }

    if (isColorModeSet()) {
      return
    }

    // setColorMode(storedTheme)
    setColor(storedTheme)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const setColor = (color) => {
    // store.dispatchEvent({ type: 'set', state: { theme: color } })
    dispatch({ type: 'set', theme: color })
    setColorMode(color)
  }

  return (
    <HashRouter>
      <Suspense
        fallback={
          <div
            className="bg-white"
            style={{
              height: '100%',
              minHeight: '100vh',
              overflow: 'hidden',
              msOverflowX: 'hidden',
              msOverflowY: 'hidden',
            }}
          >
            <FormLoading />
          </div>
        }
      >
        <Routes>
          <Route exact path="/login" name="Login Page" element={<Login />} />
          <Route exact path="/register" name="Register Page" element={<Register />} />
          <Route exact path="/404" name="Page 404" element={<Page404 />} />
          <Route exact path="/500" name="Page 500" element={<Page500 />} />
          {/* <Route path="/apps/email/*" name="Email App" element={<EmailApp />} /> */}
          <Route path="*" name="Home" element={<DefaultLayout />} />
        </Routes>
      </Suspense>
    </HashRouter>
  )
}

export default App
