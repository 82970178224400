import React from 'react';
import Loading from './CSpinner';

const FormLoading = () => {

    return <div
        style={{
            display: 'flex',
            alignItems: 'end',
            minHeight: '45vh',
        }}
    >
        <Loading />
    </div>
};

export default FormLoading;
