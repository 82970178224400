import { CSpinner } from '@coreui/react-pro';
import React from 'react';
import './CSpinner.css';

const Loading = (props) => {
    let width = "2rem";
    let height = "2rem";
    let borderWidth = "0.25rem";

    if(props.width) width = props.width;
    if(props.height) height = props.height;
    if(props.borderWidth) borderWidth = props.borderWidth;

    return <div className='spinner-container'>
        <CSpinner 
            color='primary' 
            style={{
                width: width,
                height: height,
                borderWidth: borderWidth,
            }}
        />
    </div>
};

export default Loading;
